KXL.module('AuthApp.BannedDialog', function(BannedDialog, KXL, Backbone, Marionette, $, _) {
    BannedDialog.View = Marionette.Layout.extend({
        className: 'kx-banned-dialog',
		template: Templates['auth/banned_dialog/banned_dialog'],
        events: {
			'click #kx-ban-copy-button': 'onCopyButtonClick'
		},
        onCopyButtonClick: function() {
            navigator.clipboard.writeText(JSON.stringify(this.model.attributes.message));
        }
    });
});
