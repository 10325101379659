KXL.module('AuthApp.BannedDialog', function(BannedDialog, KXL, Backbone, Marionette, $, _) {
    BannedDialog.Model = Backbone.Model.extend({
        defaults: {
		    message : "{\"id\":\"\",\"aggregateId\":\"\"}",
        }
    });

	var Controller = Marionette.Controller.extend({
        show: function(banDetails) {
            var message = banDetails.message;
            if (message && typeof message !== 'object') {
                message = JSON.parse(message);
            }

            var model = new BannedDialog.Model({
                message: message ? message : ""
            });

            var bannedView = new BannedDialog.View({
                model: model
            });

            KXL.request('show:default:dialog', bannedView, {
                showCloseButton: false,
                allowClose: false
            });
        }
    });

    BannedDialog.Controller = new Controller();
});
